<template>
  <div class="tab-content" id="PTtab-9" :key="'PTtab-9'" >
    <div class="pagenamPT">
      <h3 class="pagename2">포인트 적립내역</h3>
    </div>
    <div class="searchPTwrap">
      <div class="datesearchPT">
        <date-filter @search="loadList(1)"
                  @update="onChangeDate"
                  :startDate="searchDate.startDate"
                  :endDate="searchDate.endDate" :isOldYn="true"
                  @setOldYn="setOldYn" :oldYn="oldYn" />
      </div>
      <div class="datesearchM">
         <date-filter-mobile :retail="true" @search="loadList(1)"
                            @update="onChangeDateTable"
                            :defaultDay="0"
                            :startDate="mainTableDate.startDate"
                            :endDate="mainTableDate.endDate"
                            :isOldYn="true"
                            :id="'main-date-checkbox1'"
                            @setOldYn="setOldYn" :oldYn="reqData.oldYn"
        />
      </div>
    </div>
    <!-- <div class="searchPTwrap">
      <div class="idsearchwrap">
        <select v-model="searchType">
          <option value="memId">아이디</option>
          <option value="recommenderId">추천인아이디</option>
          <option value="memNick">닉네임</option>
        </select>
        <input v-model="searchId" @keydown.enter="loadMainTable(1)" class="ml5">
        <button class="idschbtn" @click="loadMainTable(1)"><img src="../../../assets/img/search.png"></button>
      </div>
    </div> -->
    <!-- <ul class="strbtnPT mb20">
      <li>{{$t('front.point.leftPoint')}}<span>{{thousand(sum ? sum.pointAmt: 0)}} P</span></li>
      <li>{{$t('front.point.monthPoint')}}<span>{{thousand(sum ? sum.thisMonthPoint : 0)}} P</span></li>
      <li>{{$t('front.point.prevMonthPoint')}}<span>{{thousand(sum ? sum.preMonthPoint: 0)}} P</span></li>
      <li>{{$t('front.point.allPoint')}}<span>{{thousand(sum ? sum.totalInPoint : 0)}} P</span></li>
      <li>{{$t('front.point.allUsePoint')}}<span>{{thousand(sum ? sum.totalOutPoint : 0)}} P</span></li>
    </ul> -->
    <div class="strTablescr">
      <div class="strTablePC">
        <table class="strTablePT">
          <colgroup>
            <col width="13%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="15%">
            <col width="10%">
            <col width="10%">
            <col width="12%">
          </colgroup>
          <thead>
            <tr>
              <th>적립일</th>
              <th>아이디</th>
              <th>사이트이름</th>
              <th>멤버아이디</th>
              <th>종류</th>
              <th>베팅금액</th>
              <th>퍼센트</th>
              <th>보너스퍼센트</th>
              <th>적립포인트</th>
            </tr>
          </thead>
          <tbody>
            <!-- <template v-if="list.length > 0">
              <tr v-for="item in list" v-bind:key="item.gameIdx">
                <td>{{dateFormatAll(item.regDt)}}</td>
                <td>{{item.memId}}</td>
                <td></td>
                <td></td>
                <td>{{item.codeName}}</td>
                <td>{{thousand(item.betAmt)}}</td>
                <td>{{item.pointRate}}%</td>
                <td>{{item.pointBonusRate}}%</td>
                <td>{{thousand(item.point_inAmt)}}P</td>
              </tr>
            </template> -->
            <tr>
              <td>2023-10-10</td>
              <td>ID</td>
              <td>ex) ROYAL</td>
              <td>member ID</td>
              <td>codeName</td>
              <td>123,456</td>
              <td>0 %</td>
              <td>0 %</td>
              <td>0 P</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="strTableM">
        <div class="strTablePTM">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  partnerLevels, retailMemRate, retailMyMemberListByUser, retailUpdate
} from '@/api/retail'
// import { getPointList } from '@/api/point'

import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { getPointList } from '@/api/point'
export default {
  name: 'PointList',
  components: {
    DateFilter,
    DateFilterMobile
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        searchType: 'OUTER',
        chkTodayYn: 'N',
        startDate: '',
        endDate: '',
        offset: -1,
        oldYn: 'N',
        searchMemId: ''
      },
      searchType: 'memId',
      searchId: '',
      list: [],
      pageInfo: {
        page: 1,
        count_per_list: 40,
        tatal_list_count: 10
      }
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.onLoadRate()
    this.getPartnerLevels()
    this.loadList()
    this.reqData.startDate = this.mainTableDate.startDate.split(' ')[0]
    this.reqData.endDate = this.mainTableDate.endDate.split(' ')[0]
    await this.loadMainTable()
    this.emitter.emit('Loading', false)
  },
  methods: {
    loadList (page) {
      const params = {
        pointType: 'in',
        page: page || this.pageInfo.page,
        startDate: this.searchDate.startDate,
        endDate: this.searchDate.endDate
      }

      getPointList(params).then(response => {
        console.log('getPointList : ', response)
        const result = response.data
        if (result.resultCode === '0') {
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
          const data = result.data
          this.list = data.list
          this.sum = data.sum
        }
      })
    },
    async onUpdateChildrenRate () {
      const rateList = this.myChildrenRate

      for (let i = 0, iLen = rateList.length; i < iLen; i++) {
        const item = rateList[i]
        if (item.casinoLRError ||
          item.casinoPRError ||
          item.hcasinoPRError ||
          item.hcasinoLRError ||
          item.slotLRError ||
          item.slotPRError ||
          item.miniLRError ||
          item.miniPRError
        ) {
          return
        }
      }

      const confirm = await this.onConfirm('front.common.confirmSave')
      if (confirm) {
        const param = {
          siteId: '',
          memId: '',
          rateList: rateList
        }

        retailUpdate(param).then(res => {
          const data = res.data
          if (data.resultCode === '0') {
            this.onCheck('front.recommender.complete')
            this.onLoadRate()
          }
        })
      }
    },
    onLoadRate () {
      retailMemRate({}).then(res => {
        const data = res.data
        if (data.resultCode === '0') {
          this.myRate = data.data.myCategoryRate
          this.myChildrenRate = data.data.botCategoryRateList
        }
      })
    },
    thousand,
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangeDateTable (value) {
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    async loadMainTable (page) {
      if (!page) {
        page = 1
      }

      this.emitter.emit('Loading', true)
      const params = {
        ...this.reqData,
        count_per_list: 20,
        page: page
      }

      if (this.searchId) {
        if (this.searchType === 'memId') {
          params.searchMemId = this.searchId
        } else {
          params.searchRecommandId = this.searchId
        }
      }

      this.mainTableDate.startDate = this.reqData.startDate
      this.mainTableDate.endDate = this.reqData.endDate

      const today = new Date()
      if (params.endDate === getDateStr(today)) {
        params.chkTodayYn = 'Y'
      } else {
        params.chkTodayYn = 'N'
      }

      console.log('[req][retailMyCalculateByUser] : ', params)
      await retailMyMemberListByUser(params).then(res => {
        console.log('[res][retailMyCalculateByUser] : ', res)
        window.scrollTo(0, 0)
        const data = res.data
        if (data.resultCode === '0') {
          this.list = data.data.searchList
          this.pageInfo = data.data.pageInfo
          this.total = data.data.searchTotal
        }

        this.emitter.emit('Loading', false)
      })
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/striNew.css"></style>

<style scoped>
.datesearch {margin: 0;}
.searchPTwrap { margin-bottom: 20px; display: flex; gap:20px; align-items: center;}
.searchPTwrap label { font-size: 13px; margin-right: 5px;}
.searchPTwrap input {border-radius: 5px; border: 1px solid #969696; height: 28px; box-sizing: border-box; text-indent: 5px; margin-right: 5px; }
.searchPTwrap .idschbtn {background: #5068d4; border: 0; padding: 6px 8px; border-radius: 8px; vertical-align: middle; height: 28px;}
.strbtnPT li:last-child {height: auto;width: 100%; display: block; cursor: auto; background-image: none; color: #000;}
</style>
